import {useEffect, useState} from 'react';
import './App.css';
import ExperienceList from './experience-list/experienceList';

const apiUrl = process.env.REACT_APP_API_URL;
// const experienceList = [
//   {
//     id: 'all-designs',
//     label: 'All Designs',
//   },
//   {
//     id: '1',
//     label: 'very long',
//   },
//   {
//     id: '2',
//     label: 'very short',
//   },
// ];

function App() {
  const [experienceList, setExperienceList] = useState([]);

  useEffect(() => {
    console.log('stuff22', process.env);
    fetch(`http://${apiUrl}/experience`).then(res => res.json())
      .then(
        (result) => {
          console.log('res', result);
          setExperienceList(result);
        },
        (error) => {
          console.log('error', error)
        }
      )
  }, []);

  return (
    <div className="App">
      <h1>Krasava.com</h1>
      <ExperienceList list={experienceList} />
    </div>
  );
}

export default App;
