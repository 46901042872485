import React from 'react';
import Experience from "./experience/experience";

const ExperienceList = ({list}) => {
  return (
    <div className="grid-container">
      {
        list.map((item) =>
          <Experience key={item.id} item={item}/>
        )
      }
    </div>
  );
};

export default ExperienceList;
